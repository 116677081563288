<template>
    <div>

        <div class="container_site">
        

        <div class="embed-container">
            <iframe frameborder="0" scrolling="no" marginheight="0" marginwidth="0" :src="getClient().url_argis_map">
            </iframe>
        </div>
  
   
        </div>

    </div>
</template>
<script>


export default {
    data() {
        return {

            screenWidth: window.innerWidth,
            url: 'map/logo.jpeg'
        }
    },

   

    methods: {
        //  slickOptions: {
        //         slidesToShow: 3,
        //         // Any other options that can be got from plugin documentation
        //     },


     onResize() {
      this.screenWidth = window.innerWidth
    }

    },

  
  mounted() {


      this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })

   
 
  },


  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize); 
  },

}
</script>

<style scoped>
.embed-container {
    position: relative; 
    padding-bottom: 100%; 
    height: 0; 
    max-width: 100%;
    margin-bottom: 5%;
} 
.embed-container iframe, .embed-container object, .embed-container iframe{
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%;
} 
small{
    position: absolute; 
    z-index: 40; 
    bottom: 0; 
    margin-bottom: -15px;
}
</style>